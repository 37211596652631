// 首頁 活動 元件
import { useRouter } from "next/navigation";
import moment from "moment";
import Image from "next/image";

const IndexEventComponent = ({ t, className, event = {}, isDragging }) => {
  const router = useRouter();
  // 活動狀態樣式、文字
  const statusType = {
    1: { background: "bg-[#29afcb]", text: t("ING") }, // 進行中
    2: { background: "bg-[#d8d8d8]", text: t("UPCOMING") }, // 即將開始
    3: { background: "bg-[#1e1e1e]", text: t("END") }, // 已截止
  };

  return (
    <a
      href={`/events/${event?.slug || event?.id}`}
      className="user-drag"
      onClick={(e) => e.preventDefault()}
    >
      <div
        className={`img-box group px-[18px] pb-[23px] flex items-end flex-shrink-0 w-[337px] h-[400px] rounded-[4px] overflow-hidden
      ${className}`}
        onClick={() => {
          if (!isDragging) router.push(`/events/${event?.slug || event?.id}`);
        }}
      >
        {/* 活動圖片 */}
        <Image
          loading="lazy"
          className="z-[0] hover-img"
          width={337}
          height={400}
          src={event?.image || event?.banner || "/images/index/event.png"}
          alt={event?.title}
          sizes="400px"
        />

        {/* 活動狀態 */}
        <span
          className={`absolute backdrop-blur-[10px] bg-opacity-[.5] z-[2] top-[7px] left-[7px] rounded-[2px] text-btn1 px-[11px] py-[8px] text-[#fff]
          ${statusType?.[event?.progressStatus]?.background}`}
        >
          {statusType?.[event?.progressStatus]?.text}
        </span>

        <div className="space-y-[2px] text-center relative z-[2] w-full trans group-hover:translate-y-[-7px]">
          {/* 活動類型 */}
          <span className="inline-block pb-[6px] text-btn1 text-[#fff] border-b-1 border-[#fff]">
            {t(event?.label?.[0]?.toUpperCase())}
          </span>

          {/* 活動標題 */}
          <h4 className="text-bd2b text-[#fff]">{event?.title}</h4>

          {/* 活動時間 */}
          <span className="inline-block text-[#C7C7C7] text-bd1n">
            {moment(event?.beginTime).format("YYYY.MM.DD")} -{" "}
            {moment(event?.endTime).format("MM.DD")}
          </span>
        </div>

        {/* 遮罩 */}
        <div
          className="absolute w-full h-[270px] bottom-0 left-0 z-[1]
        bg-gradient-to-b from-transparent to-black"
        />
      </div>
    </a>
  );
};

export default IndexEventComponent;
