// 恐癱指數說明彈窗
"use client";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { popState } from "@/slices/popSlice";

const GreedyPop = ({ greed }) => {
  const { t } = useTranslation();
  const { greedyModal } = useSelector(popState);

  const timerRef = useRef(null);
  const [greedNumber, setGreedNumber] = useState(0); // 指數

  const handleGreed = () => {
    timerRef.current = setInterval(() => {
      setGreedNumber((prev) => prev + 1);
    }, 50);
  };

  useEffect(() => {
    if (greed && greedyModal && greed > greedNumber) handleGreed();

    return () => {
      clearInterval(timerRef.current);
    };
  }, [greed, greedyModal]);

  useEffect(() => {
    if (greedNumber >= greed) clearInterval(timerRef.current);
  }, [greedNumber]);

  return (
    <div
      className={`!m-0 slideIn absolute z-[0] top-[50px] left-[45px] w-[397px] bg-white rounded-[6px] border-1 border-black1
   py-[20px] px-[25px] items-center space-x-[35px] uxl:left-[30px] ${
     greedyModal ? "flex" : "hidden"
   }`}
    >
      <div
        className="progress-circle flex-shrink-0"
        style={{
          "--percent": greedNumber,
          "--color": greedNumber > 49 ? "#1AB260" : "#D3392F",
        }}
      >
        <span className="absolute z-[1] left-[50%] translate-x-[-50%] bottom-[4px]">
          {greedNumber > 49 ? t("GREEDY") : t("FEAR")}
        </span>
        <svg>
          <circle className="circle1" stroke="var(--inactive-color)" />
          <circle stroke="var(--color)" className="progress-value" />
        </svg>
      </div>

      <div className="space-y-[15px] text-left">
        <p className="text-btn1b text-black">{t("GREED_TITLE")}</p>
        <p className="text-btn1 text-black6 6">
          {t(greedNumber > 49 ? "GREED_INFO" : "FEAR_INFO")}
        </p>
      </div>
    </div>
  );
};

export default GreedyPop;
