const SelectedNewsSkeltonComponent = () => {
  return (
    <div
      className="flex items-center gap-x-[15px] group pb-[26px] border25 border-b-1 ulb:pb-[20px] ulg:border-0 ulg:!pb-0
    umd:!gap-x-[10px] w-full"
    >
      <div className="flex-grow space-y-[22px] skelton-all">
        {/* 標題 */}
        <p className="w-full h-[66px]" />

        {/* 新聞類別、時間 */}
        <p className="w-full h-[16px]" />
      </div>

      {/* 新聞圖片 */}
      <div className="w-[72px] h-[106px] overflow-hidden rounded-[2px] ulg:w-[124px] ulg:h-[96px] uxs:hidden skelton" />
    </div>
  );
};

export default SelectedNewsSkeltonComponent;
