// 最新專題
"use client";
import { useState, useEffect } from "react";
import { getProjectByPage } from "@/app/actions";
import { useTranslation } from "react-i18next";

import Image from "next/image";
import Link from "next/link";
import LatestProjectComponent from "../basic/LatestProjectComponent";
import LoadMore from "./LoadMore"; // 更多
import IndexProjectSkeltonComponent from "../skelton/IndexProjectSkeltonComponent";

const LatestProject = ({ locale }) => {
  const { t } = useTranslation();
  const [projectList, setProjectList] = useState([]); // 專題列表
  const [loading, setLoading] = useState(true);

  const getProjectList = async () => {
    try {
      const { data } = await getProjectByPage(locale, 0, 5);
      if (data) {
        setProjectList(data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getProjectList();
  }, []);

  return (
    <section className="pt-[58px] uxl:pt-[30px]">
      <div
        className="flex gap-x-[54px] items-end uxl:gap-x-[20px]
      ulg:flex-col ulg:gap-y-[40px] ulg:max-w-[504px] ulg:mx-auto"
      >
        {loading ? (
          <IndexProjectSkeltonComponent />
        ) : (
          <>
            {/* 最新 */}
            <Link
              href={`/project/${projectList?.[0]?.slug}`}
              className="group text-center space-y-[22px] max-w-[486px] flex-shrink-0 uxl:w-[calc(363/730*100)%]
            ulg:!w-full ulg:max-w-none"
            >
              <p className="text-bd1n text-black8 8">{t("LATEST_PROJECT")}</p>
              {/* 標題 */}
              <h3 className="text-h4 text-black  !mt-[5px] hover-text">
                {projectList?.[0]?.title}
              </h3>

              {/* 圖片 */}
              <div className="img-box w-full aspect-[486/372] rounded-[4px] overflow-hidden uxl:aspect-[363/267] ulg:!aspect-[505/265]">
                {projectList?.[0]?.image && (
                  <Image
                    loading="lazy"
                    className="hover-img"
                    src={
                      projectList?.[0]?.image ||
                      "/images/news/default_related.svg"
                    }
                    alt={projectList?.[0]?.title}
                    width={486}
                    height={372}
                    sizes="(max-width: 1199px) 800px, 1100px"
                  />
                )}
              </div>

              <p className="text-bd1b text-black9 hover-text line-clamp-1">
                {projectList?.[0]?.subTitle}
              </p>
              <p className="text-left text-bd1m text-black  line-clamp-2 hover-text !mt-[12px]">
                {projectList?.[0]?.brief}
              </p>
            </Link>

            {/* 其他 */}
            <div className="w-full flex-grow">
              {projectList?.slice(1, 6)?.map((project, i) => (
                <LatestProjectComponent
                  key={"index-project" + i}
                  project={project}
                  i={i}
                />
              ))}
            </div>
          </>
        )}
      </div>

      {/* 更多 */}
      <LoadMore path="/project" />
    </section>
  );
};

export default LatestProject;
