import apiClient from "./apiClient";

class EventClient {
  // 取得活動列表
  getEventsList = (locale) =>
    apiClient.get("/activity/list?pageIndex=0&pageSize=5", {
      headers: {
        "X-LANG": "zh-" + locale,
      },
    });
}

const eventClient = new EventClient();

export default eventClient;
