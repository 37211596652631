// 活動一覽
"use client";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import eventClient from "@/common/apiClient/eventClient";

import IndexEventComponent from "../basic/IndexEventComponent"; // 活動元件
import IndexEventSkeltonComponent from "../skelton/IndexEventSkeltonComponent"; // 活動 loading 元件
import LoadMore from "./LoadMore"; // 更多
import useHorizontalDrag from "@/hook/useHorizontalDarg";

const IndexEvents = ({ locale }) => {
  const { t } = useTranslation();
  const { wrapRef, mouseDownHandler, isDragging } = useHorizontalDrag(); // 處理拖拉用

  const [events, setEvents] = useState([]); // 活動列表
  const [loading, setLoading] = useState(true);

  // 取得活動列表
  const getEventsList = async () => {
    try {
      const result = await eventClient.getEventsList(locale);
      if (result) {
        setEvents(result.records);

        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getEventsList();
  }, []);

  return (
    <div className="index-scroll-wrapper pt-[35px] pb-[1px]">
      <div className="bg" />

      <div className="content">
        <p>{t("EVENTS")}</p>
        <div
          className="scroll-wrapper w-full pl-[30px] uxl:pl-[26px] usm:!pl-[15px]"
          ref={wrapRef}
          onMouseDown={(e) => mouseDownHandler(e)}
        >
          <div className="gap-x-[30px] ulg:gap-x-[15px]">
            {/* loading 畫面 */}
            {loading
              ? new Array(4)
                  .fill(0)
                  .map((_, i) => (
                    <IndexEventSkeltonComponent
                      key={"index-skelton-event" + i}
                    />
                  ))
              : //  活動列表
                events?.map((v, i) => (
                  <IndexEventComponent
                    key={i + "index-event-list" + v?.id}
                    locale={locale}
                    t={t}
                    event={v}
                    className="w-[337px] h-[400px] ulg:w-[336px] ulg:h-[246px]"
                    isDragging={isDragging}
                  />
                ))}
          </div>
        </div>
      </div>

      <div className="usm:px-[15px]">
        <LoadMore path="/events" />
      </div>
    </div>
  );
};

export default IndexEvents;
