import { useState, useRef } from "react";

const useHorizontalDrag = () => {
  const wrapRef = useRef(null);
  const [isDragging, setIsDragging] = useState(true); //判斷是否click
  let pos = {
    left: 0,
    top: 0,
    x: 0,
    y: 0,
  };

  // 點擊滑鼠
  const mouseDownHandler = (e) => {
    if (!wrapRef.current || e.button === 2 || e.button === 1) return;
    setIsDragging(false);
    pos = {
      left: wrapRef.current.scrollLeft,
      top: wrapRef.current.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };
    window.addEventListener("mousemove", mouseMoveHandler);
    window.addEventListener("mouseup", mouseUpHandler);
  };

  // 移動滑鼠
  const mouseMoveHandler = (e) => {
    setIsDragging(true);
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;
    wrapRef.current.scrollLeft = pos.left - dx;
    wrapRef.current.scrollTop = pos.top - dy;
  };

  // 結束點擊
  const mouseUpHandler = () => {
    window.removeEventListener("mousemove", mouseMoveHandler);
    window.removeEventListener("mouseup", mouseUpHandler);
  };

  return {
    wrapRef,
    mouseDownHandler,
    isDragging,
  };
};

export default useHorizontalDrag;
