// 快訊輪播
"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import Link from "next/link";

import "../../styles/swipers.scss"; // 輪播樣式

const FlashSwiper = ({ flashes }) => {
  return (
    <Swiper
      id="flash-swiper"
      className="w-full overflow-hidden !mt-[8px]"
      loop={true}
      pagination={{ clickable: true }}
      modules={[Autoplay, Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      autoplay={{
        delay: 5000,
      }}
    >
      {flashes?.map((f) => (
        <SwiperSlide
          key={f?.[0].title}
          className="pb-[15px] [&_a]:py-[14px] [&_a]:inline-block text-btn1 text-black9"
        >
          <Link
            href={`/flash/${f?.[0]?.id}`}
            className="group"
            prefetch={false}
          >
            <span className="hover-text line-clamp-2 !underline-offset-1">
              {f?.[0]?.title}
            </span>
          </Link>
          <hr className="border-dotted w-full border-black4 border-opacity-[.7]" />
          <Link
            href={`/flash/${f?.[1]?.id}`}
            className="group"
            prefetch={false}
          >
            <span className="hover-text line-clamp-2 !underline-offset-1">
              {f?.[1]?.title}
            </span>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default FlashSwiper;
