// 首頁 加密學院 元件
import Link from "next/link";
import Image from "next/image";

const IndexAcademyComponent = ({ academy }) => {
  return (
    <Link href={`/academy/${academy?.slug}`} className="group">
      {/* 圖片 */}
      <div className="img-box w-full aspect-[337/214] rounded-[4px] overflow-hidden">
        <Image
          loading="lazy"
          className="hover-img"
          src={academy?.imageFb || "/images/news/default_related.svg"}
          alt={academy?.title + "-" + academy?.brief}
          width={337}
          height={214}
          sizes="400px"
        />
      </div>

      {/* 標題 */}
      <h4 className="hover-text text-bd2b text-black">{academy?.title}</h4>
    </Link>
  );
};

export default IndexAcademyComponent;
