// 恐懼貪婪指數
"use client";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setGreedyModal } from "@/slices/popSlice";
import Image from "next/image";
import Link from "next/link";

import "../../styles/greedy.scss";

const Greedy = ({ greed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Link
      href="https://www.cryptocity.tw/news/what-is-crypto-fear-and-greed-index"
      target="_blank"
      rel="noreferrer noopener"
      className="relative cp flex items-center space-x-[20px] px-[15px] py-[8px] bg-white rounded-[40px] no-select !ml-0 flex-shrink-0 z-[1] group"
      onMouseEnter={() => dispatch(setGreedyModal(true))}
      onMouseLeave={() => dispatch(setGreedyModal(false))}
    >
      <div className="text-btn1b text-black center-center flex-shrink-0">
        {`${t("GREED")}  ${greed}`}
        <div
          className="ml-[10px] center-center trans flex-shrink-0 h-[15px] w-[15px] img-box after:content-none opacity-[.3] group-hover:opacity-[1]
          dark:opacity-[1]"
        >
          <Image
            width={15}
            height={15}
            src="/images/icon/icon_question.svg"
            alt="question"
            sizes="15px"
          />
        </div>
      </div>
      <div className="relative w-[235px] h-[2px] bg-gradient-to-r from-[#d8726d] via-[#d0c58c] to-[#6ca972]">
        {/* 牛熊 gif */}
        <Image
          style={{ left: `${greed}%` }}
          className="absolute w-[28px] aspect-[1/1] top-[50%] translate-y-[-50%] translate-x-[-20px] moving-element"
          src={`/images/icon/icon_${greed > 49 ? "bullish" : "bearish"}.webp`}
          alt="icon"
          loading="lazy"
          width={28}
          height={28}
          quality={50}
          sizes="20px"
        />
      </div>

      <div />
    </Link>
  );
};

export default Greedy;
