import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/index/CoinPrice.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/index/FlashSwiper.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/index/Greedy.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/index/GreedyPop.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/index/IndexAcademyTab.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/components/index/IndexColumn.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/components/index/IndexEvents.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/components/index/IndexPopular.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/components/index/LatestProject.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/index/LoadMore.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/index/NewsTabContent.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/index/Subscribe.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/layout/footer/FooterLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/layout/footer/FooterSocial.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/cc-web-v4/components/TranslationsProvider.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/cc-web-v4/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
