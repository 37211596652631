// 大家都在看 新聞元件
import { useTranslation } from "react-i18next";
import { useRouter } from "next/navigation";
import moment from "moment";
import Image from "next/image";

const PopularNewsComponent = ({ i, news, isDragging }) => {
  const router = useRouter();
  const { t } = useTranslation();

  // 點擊分類跳轉
  const handleCategoryClick = (e) => {
    router.push(`/news?category=${news?.category}`);
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <a
      href={`/news/${news?.slug}`}
      className="user-drag"
      onClick={(e) => e.preventDefault()}
    >
      <div
        onClick={() => {
          if (!isDragging) router.push(`/news/${news?.slug}`);
        }}
        className="!cp relative group h-[300px] bg-white flex-shrink-0  w-[250px] pb-[22px] rounded-[4px] overflow-hidden shadow-[0px_0px_10px_0px_rgba(177,177,177,.1)"
      >
        {/* 編號順序 */}
        <span className="absolute top-0 left-0 z-[3] tabular text-[#fff] text-btn3b px-[14px] py-[10px] rounded-br-[10px] bg-[#29afcb] bg-opacity-[.5] backdrop-blur-[10px]">
          0{i}
        </span>

        {/* 新聞圖片 */}
        <div className="img-box mb-[14px] w-[250px] h-[161px] overflow-hidden after:content-none">
          <Image
            loading="lazy"
            className="hover-img z-[0]"
            src={
              news?.image || news?.imageFb || "/images/news/default_related.svg"
            }
            alt={news?.title}
            width={250}
            height={161}
            sizes="240px"
          />
        </div>

        <div className="px-[20px] flex flex-col justify-between gap-y-[20px] h-[103px]">
          {/* 新聞標題 */}
          <h3 className="hover-text line-clamp-3 text-btn3b text-black9 9">
            {news?.title}
          </h3>

          {/* 發布時間＋新聞分類 */}
          <p className="between-center text-btn1">
            <span className="text-black3">
              {moment(news?.displayTime).format("YYYY.MM.DD")}
            </span>
            <span className="text-pale" onClick={(e) => handleCategoryClick(e)}>
              {t(news?.category?.toUpperCase())}
            </span>
          </p>
        </div>
      </div>
    </a>
  );
};

export default PopularNewsComponent;
