const BannerSkelton = () => {
  return (
    <div className="w-full flex items-center gap-x-[40px] ulb:gap-x-[15px] ulg:flex-col">
      <div className="flex-grow ulg:w-full ulg:px-[30px] ulg:py-[18px] usm:!px-[15px]">
        <p className="w-[120px] h-[17px] skelton" />
        <p className="w-full h-[135px] skelton mt-[20px] mb-[30px] ulb:mb-0 ulb:h-[66px]" />
        <p className="w-full h-[64px] skelton ulb:hidden" />
      </div>
      <div
        className="w-[calc(636/1072*100)%] skelton ulb:w-[calc(390/730*100)%] aspect-[390/225] rounded-[4px]
      ulg:order-[-1] ulg:aspect-[768/425] ulg:!w-screen ulg:rounded-none"
      />
    </div>
  );
};

export default BannerSkelton;
