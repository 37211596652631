const LatestNewsSkeltonComponent = () => {
  return (
    <div className="flex items-center gap-x-[35px] uxl:gap-x-[15px] umd:!gap-x-[10px] w-full">
      <div className="flex flex-col justify-between gap-y-[20px] flex-grow skelton-all">
        {/* 新聞標題 */}
        <p className="w-full h-[75px]" />

        {/* 新聞類別、時間 */}
        <p className="w-full h-[16px]" />
      </div>

      {/* 新聞圖片 */}
      <div className="w-[168px] h-[130px] rounded-[2px] flex-shrink-0 uxl:w-[119px] uxl:h-[92px] ulg:!w-[124px] ulg:!h-[96px] uxs:hidden skelton" />
    </div>
  );
};

export default LatestNewsSkeltonComponent;
