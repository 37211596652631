// 首頁 加密學院 tab 內容
"use client";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ACADEMY_CATEGORY_MAP } from "@/common/constant";
import IndexAcademyComponent from "../basic/IndexAcademyComponent";
import newsClient from "@/common/apiClient/newsClient";

const IndexAcademyTab = ({ locale }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(0); // 學院分類
  const [academyList, setAcademyList] = useState([]); // 學院列表

  // 取得學院列表
  const getAcademyList = async () => {
    try {
      const { data } = await newsClient.getAcademyList(locale);
      if (data) {
        setAcademyList(data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAcademyList();
  }, []);

  return (
    <div
      className="w-full uxl:[&>div>div]:px-[26px] usm:[&>div>div]:px-[15px] ulg:[&>div]:w-screen ulg:[&>div]:relative
      ulg:[&>div]:left-[50%] ulg:[&>div]:translate-x-[-50%]"
    >
      {/* tab 按鈕 暫時隱藏 */}
      {/* <div className="noScrollX">
        <div className="center-center gap-x-[8px] text-black6 mb-[25px] ulg:mb-[20px] mx-auto usm:!px-0 w-max usm:gap-x-0">
          {Object.values(ACADEMY_CATEGORY_MAP).map((tab, i) => (
            <React.Fragment key={`academy-tab-${tab}-${i}`}>
              <button
                onClick={() => setCategory(i)}
                className="px-[20px] py-[12px] usm:px-[15px]"
              >
                <span
                  className={`trans text-btn1b border-b-1
                ${
                  category === i
                    ? "text-pale border-pale dark:text-dpale dark:border-dpale"
                    : "text-black dark:text-dblack border-transparent hover:border-black dark:hover:border-dblack"
                }`}
                >
                  {t("TAB_ACADEMY_" + i)}
                </span>
              </button>
              {i < 3 && (
                <span className="w-[1px] h-[10px] border-l-1 border25" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div> */}

      {/* tab 內容 */}
      <div className="noScrollX">
        <div className="flex justify-center items-start gap-x-[30px] ulg:gap-x-[15px] usm:!px-[15px] w-full min-w-max [&>*]:w-[337px] [&>*]:space-y-[20px] [&>*]:flex-shrink-0">
          {loading
            ? new Array(3).fill(0).map((_, i) => (
                <div className="skelton-all" key={"index-skelton-academy" + i}>
                  <div className="w-full h-[214px] rounded-[4px]" />
                  <p className="w-full h-[70px]" />
                </div>
              ))
            : academyList?.[category]?.map((academy, i) => (
                <IndexAcademyComponent
                  key={"index-academy" + i + academy?.slug}
                  academy={academy}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default IndexAcademyTab;
