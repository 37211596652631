// 最新新聞元件
import moment from "moment";
import { useRouter } from "next/navigation";
import Link from "next/link";
import Image from "next/image";

const LatestNewsComponent = ({ news, t }) => {
  const router = useRouter();

  // 點擊分類跳轉
  const handleCategoryClick = (e) => {
    e.preventDefault();
    router.push(`/news?category=${news?.category}`);
  };

  return (
    <Link
      href={`/news/${news?.slug}`}
      className="flex gap-x-[35px] group uxl:gap-x-[15px] umd:!gap-x-[10px] w-full"
    >
      <div className="flex flex-col justify-between gap-y-[20px] flex-grow">
        {/* 新聞標題 */}
        <h2 className="hover-text text-bd2b text-black9 line-clamp-3 leading-[normal] ulg:text-btn3b lighten-text">
          {news?.title}
        </h2>

        {/* 新聞類別、時間 */}
        <p className="text-btn2">
          <span
            className="trans text-pale group-hover:text-pale9"
            onClick={handleCategoryClick}
          >
            {t(news?.category?.toUpperCase())}
          </span>
          <span className="text-btn1 text-black2"> ／ </span>
          <span className="text-black4">
            {moment(news?.displayTime).format("YYYY.MM.DD")}
          </span>
        </p>
      </div>

      {/* 新聞圖片 */}
      <div className="img-box w-[168px] h-[130px] rounded-[2px] overflow-hidden flex-shrink-0 uxl:w-[119px] uxl:h-[92px] ulg:!w-[124px] ulg:!h-[96px] uxs:hidden">
        <Image
          width={168}
          height={130}
          className="hover-img"
          src={
            news?.image || news?.imageFb || "/images/news/default_related.svg"
          }
          alt={news?.title}
          sizes="(max-width: 1023px) 130px, 200px"
        />
      </div>
    </Link>
  );
};

export default LatestNewsComponent;
