// 幣價顯示 (btc,eth,sol)
"use client";
import { useEffect, useState } from "react";
import useComma from "@/hook/useComma";
import Image from "next/image";

const CoinPrice = () => {
  const numberComma = useComma();
  // 幣價
  const [btcPrice, setBtcPrice] = useState(null);
  const [ethPrice, setEthPrice] = useState(null);
  const [solPrice, setSolPrice] = useState(null);

  useEffect(() => {
    const btcSocket = new WebSocket(
      "wss://stream.binance.com:9443/ws/btcusdt@ticker"
    );
    const ethSocket = new WebSocket(
      "wss://stream.binance.com:9443/ws/ethusdt@ticker"
    );
    const solSocket = new WebSocket(
      "wss://stream.binance.com:9443/ws/solusdt@ticker"
    );

    btcSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setBtcPrice({
        price: Number(data.c).toFixed(0),
        priceChangePercent: Number(data.P).toFixed(2),
      });
    };

    ethSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setEthPrice({
        price: Number(data.c).toFixed(0),
        priceChangePercent: Number(data.P).toFixed(2),
      });
    };

    solSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setSolPrice({
        price: Number(data.c).toFixed(0),
        priceChangePercent: Number(data.P).toFixed(2),
      });
    };

    return () => {
      btcSocket.close();
      ethSocket.close();
      solSocket.close();
    };
  }, []);

  // if (loading) return <div className="skelton flex-grow h-[17px] w-[632px]" />;
  if (!btcPrice || !ethPrice || !solPrice) return <></>;

  return (
    <div className="flex between-center flex-grow space-x-[33px] no-select relative z-[1] tabular">
      <div className="flex items-center space-x-[10px]">
        <Image
          src={`/images/icon/icon_btc.svg`}
          alt="btc"
          width={16}
          height={16}
        />
        <span className="text-btn1b text-black ">BTC</span>
        <span className="text-btn1 text-black ">
          ${numberComma(btcPrice?.price)}
        </span>
        <span
          className={`text-btn1b ${
            btcPrice?.priceChangePercent > 0
              ? "text-[#1ab260]"
              : "text-[#D3392F]"
          }`}
        >
          {btcPrice?.priceChangePercent}%
        </span>
      </div>

      <div className="flex items-center space-x-[10px]">
        <Image
          src={`/images/icon/icon_eth.svg`}
          alt="eth"
          width={16}
          height={16}
        />
        <span className="text-btn1b text-black ">ETH</span>
        <span className="text-btn1 text-black ">
          ${numberComma(ethPrice?.price)}
        </span>
        <span
          className={`text-btn1b ${
            ethPrice?.priceChangePercent > 0
              ? "text-[#1ab260]"
              : "text-[#D3392F]"
          }`}
        >
          {ethPrice?.priceChangePercent}%
        </span>
      </div>

      <div className="flex items-center space-x-[10px]">
        <Image
          src={`/images/icon/icon_sol.svg`}
          alt="sol"
          width={16}
          height={16}
        />
        <span className="text-btn1b text-black ">SOL</span>
        <span className="text-btn1 text-black ">
          ${numberComma(solPrice?.price)}
        </span>
        <span
          className={`text-btn1b ${
            solPrice?.priceChangePercent > 0
              ? "text-[#1ab260]"
              : "text-[#D3392F]"
          }`}
        >
          {solPrice?.priceChangePercent}%
        </span>
      </div>
    </div>
  );
};

export default CoinPrice;

// "use client";
// import { useState, useEffect, useCallback } from "react";
// import Image from "next/image";
// import useComma from "@/hook/useComma";
// // import CoinFetch from "./CoinFetch";
// import { INDEX_API_ROUTER_PATH } from "@/common/constant";

// const CoinPrice = () => {
//   const [coins, setCoins] = useState([]);
//   const numberComma = useComma();

//   // 取得幣價
//   const getPrice = useCallback(async () => {
//     await fetch(INDEX_API_ROUTER_PATH.getPrice)
//       .then((res) => res.json())
//       .then((data) => {
//         const coin = data.map((c) => ({
//           ...c,
//           name: c?.symbol.toLowerCase().replace("usdt", ""),
//           lastPrice: Number(c?.lastPrice).toFixed(0),
//           priceChangePercent: Number(c?.priceChangePercent).toFixed(2),
//         }));
//         setCoins(coin);
//       })
//       .catch((e) => console.error(e));
//   });

//   useEffect(() => {
//     getPrice();
//   }, []);

//   return (
//     <div className="flex between-center flex-grow-1 space-x-[33px] no-select relative z-[1] tabular">
//       {coins?.map((coin, i) => (
//         <div
//           key={coin?.symbol + i}
//           className="flex items-center space-x-[10px]"
//         >
//           <Image
//             src={`/images/icon/icon_${coin?.name}.svg`}
//             alt={coin?.name}
//             width={16}
//             height={16}
//           />
//           <span className="text-btn1b text-black ">
//             {coin?.name?.toUpperCase()}
//           </span>
//           <span className="text-btn1 text-black ">
//             ${numberComma(coin?.lastPrice)}
//           </span>
//           <span
//             className={`text-btn1b ${
//               coin?.priceChangePercent > 0 ? "text-[#1ab260]" : "text-[#D3392F]"
//             }`}
//           >
//             {coin?.priceChangePercent}%
//           </span>
//         </div>
//       ))}
//       {/* <CoinFetch getPrice={getPrice}/> */}
//     </div>
//   );
// };

// export default CoinPrice;
