// 精選新聞
import SelectedNewsComponent from "../basic/SelectedNewsComponent"; // 一則新聞
import SelectedNewsSkeltonComponent from "./SelectedNewsSkeltonComponent";

const SelectedWrapper = ({ newsList, t, loading }) => {
  return (
    <section className="noScrollX ulg:w-full">
      <div
        className="w-max between-center gap-x-[35px] pt-[26px] border-opacity-[.5] border-t-1 border25 [&>*]:w-[333px]
      uxl:gap-x-[24px] ulg:flex-col ulg:gap-y-[20px] ulg:py-[30px] ulg:w-full ulg:[&>*]:w-full ulg:border-b-1
      umd:!border-b-0 umd:!pt-[20px] umd:!pb-0 umd:!gap-y-[18px]"
      >
        {loading
          ? new Array(3)
              .fill(0)
              .map((_, i) => (
                <SelectedNewsSkeltonComponent
                  key={i + "index-selected-skelton"}
                />
              ))
          : newsList?.map((news, i) => (
              <SelectedNewsComponent
                t={t}
                key={"index-selected" + news?.id + i}
                news={news}
              />
            ))}
      </div>
    </section>
  );
};

export default SelectedWrapper;
