// 新聞banner
import moment from "moment";
import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
import Image from "next/image";
import Link from "next/link";

const IndexBanner = ({ banner }) => {
  const router = useRouter();
  const { t } = useTranslation();

  // 點擊分類跳轉
  const handleCategoryClick = (e) => {
    e.preventDefault();
    router.push(`/news?category=${banner.category}`);
  };

  return (
    <Link
      href={`/news/${banner?.slug}`}
      prefetch={false}
      className="w-full flex items-center gap-x-[40px] group ulb:gap-x-[15px]
      ulg:flex-col"
    >
      <div className="text-left ulg:w-full ulg:px-[30px] ulg:py-[18px] usm:!px-[15px] flex-grow">
        {/* 分類＋時間 */}
        <div className="text-btn1b text-pale trans group-hover:text-pale9">
          <span onClick={handleCategoryClick}>
            {t(banner?.category?.toUpperCase())}
          </span>
          <span className="text-btn1 text-black3 ml-[12px]">
            {moment(banner?.displayTime).format("YYYY.MM.DD")}
          </span>
        </div>

        <div>
          {/* 新聞標題 */}
          <h2
            className="mt-[20px] mb-[30px] text-h2 text-black underline decoration-transparent
          decoration-[1.5px] trans group-hover:decoration-[#1e1e1e] dark:group-hover:decoration-[#fff] line-clamp-4 ulb:mb-0 ulb:text-h3 ulg:mt-[13px] ulg:line-clamp-2"
          >
            {banner?.title}
          </h2>

          {/* 摘要 */}
          <p className="text-bd1m text-black9 line-clamp-2 ulb:hidden">
            {banner?.brief}
          </p>
        </div>
      </div>

      {/* 新聞圖 */}
      <div
        className="w-[calc(636/1072*100)%] ulb:w-[calc(390/730*100)%] aspect-[390/225] img-box rounded-[4px] overflow-hidden
        ulg:order-[-1] ulg:aspect-[768/425] ulg:!w-screen ulg:rounded-none"
      >
        <Image
          loading="eager"
          priority={true}
          className="hover-img"
          width={636}
          height={352}
          sizes="(max-width: 599px) 57vw, (max-width: 767px) 520px, (max-width: 1023px) 68vw ,(max-width: 1199px) 250px, (max-width: 1379px) 450px, 550px"
          src={
            banner?.image ||
            banner?.imageHome ||
            banner?.imageFb ||
            "/images/news/default_related.svg"
          }
          alt={banner?.title + "｜" + banner?.brief}
        />
      </div>
    </Link>
  );
};

export default IndexBanner;
