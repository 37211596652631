// 首頁 專欄
"use client";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getColumnListByPage } from "@/app/actions";
import ColumnListComponent from "../basic/ColumnListComponent";
import LoadMore from "./LoadMore"; // 更多

const IndexColumn = ({ locale }) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]); // 專欄列表
  const [loading, setLoading] = useState(true);

  // 取得專欄列表
  const getColumnsList = async () => {
    try {
      const result = await getColumnListByPage(locale);
      if (result) {
        setColumns(result.records);

        setTimeout(() => {
          setLoading(false);
        }, 200);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getColumnsList();
  }, []);

  return (
    <div className="relative pt-[30px] pb-[1px] !px-0 umd:pt-[20px]">
      <div className="absolute z-[-1] w-[calc(100%+120px)] h-full top-0 right-[-61px] bg-black1" />
      <p className="text-h5 pb-[25px] text-center text-black ulg:pb-[20px]">
        {t("COLUMN")}
      </p>

      {/* 專欄列表 */}
      <div className="uxl:noScrollX mx-auto ulg:relative ulg:w-screen ulg:left-[50%] ulg:translate-x-[-50%]">
        <div className="between-center gap-x-[15px] mx-auto [&>*]:w-[350px] flex-shrink-0 w-max uxl:px-[26px] usm:px-[15px]">
          {loading
            ? new Array(3).fill(0).map((_, i) => (
                // loading skelton
                <div
                  key={"index-column-skelton" + i}
                  className="rounded-[2px] w-[350px] bg-white h-[421px] skelton-all space-y-[18px] pt-[20px] px-[25px]"
                >
                  <div className="w-full h-[90px] !mb-[36px]" />
                  <div className="w-full h-[60px]" />
                  <div className="w-full h-[60px]" />
                  <div className="w-full h-[60px]" />
                </div>
              ))
            : columns?.map((column, i) => (
                <ColumnListComponent
                  key={column?.id + "index-column" + i}
                  locale={locale}
                  column={column}
                />
              ))}
        </div>
      </div>

      {/* 更多 */}
      <LoadMore path="/column" />
    </div>
  );
};

export default IndexColumn;
