const IndexProjectSkeltonComponent = () => {
  return (
    <>
      <div
        className="space-y-[22px] max-w-[486px] w-full flex-shrink-0 uxl:w-[calc(363/730*100)%]
            ulg:!w-full ulg:max-w-none skelton-all"
      >
        <p className="w-[100px] h-[32px] mx-auto" />
        <p className="h-[58px] w-full mt-[!5px]" />
        <div className="w-full aspect-[486/372] rounded-[4px] uxl:aspect-[363/267] ulg:!aspect-[505/265]" />
        <p className="w-full h-[32px]" />
        <p className="!mt-[12px] w-full h-[64px]" />
      </div>

      <div className="w-full flex-grow">
        {new Array(4).fill(0).map((_, i) => (
          <div
            key={"index-project-skelton" + i}
            className={`flex w-full skelton-all items-center gap-x-[40px] py-[23px] border-dashed border-black2
          ${i === 3 ? "pb-0 border-0" : "border-b-1"} ${
              i === 0 ? "pt-0" : ""
            } uxl:gap-x-[15px] blg:gap-x-[40px] bmd:gap-x-[40px] bsm:gap-x-[40px] usm:!gap-x-[10px]`}
          >
            <div className="text-left flex-grow">
              <p className="h-[50px] w-full" />
              <p className="mt-[20px] h-[17px] w-[200px]" />
            </div>
            <div className="w-[172px] h-[104px] rounded-[4px] uxl:w-[116px] blg:w-[172px] bmd:w-[172px] bsm:w-[172px] usm:!w-[124px] usm:!h-[96px]" />
          </div>
        ))}
      </div>
    </>
  );
};

export default IndexProjectSkeltonComponent;
